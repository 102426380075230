<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <b-img alt="logo" src="/login_logo.png">
            <h2 class="brand-text text-primary ml-1">
              한국건설기술연구원
            </h2>
          </b-img>
        </b-link>

        <b-card-title class="mb-1">
          비밀번호 찾기
        </b-card-title>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="아이디(이메일)"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="userId"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userId"
                  :state="errors.length > 0 ? false : null"
                  name="forgot-password-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button block class="btn-find-pw" type="submit">
              메일 발송
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{ path: '/auth-login' }">
            <feather-icon icon="ChevronLeftIcon" />
            로그인 하기
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import {
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BImg,
  BLink,
} from 'bootstrap-vue';
import { email, required } from '@validations';

export default {
  components: {
    BImg,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userId: 'user1@edoc.com',
      // validation
      required,
      email,
    };
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          await this.$store.dispatch('email/FETCH_GET_TEMP_PWD', this.formData);
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.btn-find-pw {
  color: #fff !important;
  background-color: #f49f17 !important;
  border-color: #f49f17 !important;
}

.btn-secondary:focus {
  color: #fff !important;
  background-color: #7367f0 !important;
  border-color: #7367f0 !important;
}
</style>
